








import { Component, Vue } from "vue-property-decorator";
import Modalfooter from "@/components/common/modalfooter.vue";

@Component({
  components: { Modalfooter }
})
export default class UserConfirmedView extends Vue {
  notification: any = {
    message: "Пользователь успешно верифицирован. Кошелек подтвержден.",
    type: 2
  };
}
